<template>
  <div class="ma-6 ml-9">
    <ScheduleSkeleton :body-rows="10" />
  </div>
</template>

<script>
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
export default {
  name: 'AppTableSkeleton',
  components: {
    ScheduleSkeleton,
  },
};
</script>