<template>
  <CollectionDetails
    hide-footer
    hide-breadcrumbs
    :show-collection-products="collectionProducts"
    :show-collection-name="collectionName"
    :show-collection-photos="collectionPhotos"
    :is-shared-collection="true" />
</template>
<script>
import CollectionDetails from '@/components/CollectionDetails';
export default {
  name: 'EmbedCollectionDetails',
  components: {
    CollectionDetails,
  },
  props: {
    collectionPhotos: {
      type: Boolean,
      default: false,
    },
    collectionProducts: {
      type: Boolean,
      default: false,
    },
    collectionName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
</style>
