<template>
  <div class="d-flex flex-wrap justify-center gap-3">
    <template v-if="showHeaderActions">
      <slot name="btns-start" />

      <!-- follow collection -->
      <CollectionFollow
        :is-show-title-icon="false"
        :is-community-collections="isCommunityCollections"
        :item="activeHeader" />

      <!-- edit btn -->
      <v-btn
        v-if="disableActions && !activeHeader.follow && !readMode"
        elevation="0"
        outlined
        @click="$emit('rename')">
        Edit
      </v-btn>

      <!-- share collection -->
      <CollectionPublishV2
        v-if="showCollectionPublish"
        :item="activeHeader" />

      <!-- suggest collection -->
      <SuggestCollectionDialog :collection-id="activeHeader.id" />

      <!-- ignore collection suggestion -->
      <v-btn
        v-if="isActiveSuggestionCollection"
        color="black"
        outlined
        @click="ignoreSuggestionHandle">
        Ignore
      </v-btn>

      <!-- dropdown menu -->
      <CollectionActionDetail
        :disable-actions="disableActions"
        :item="activeHeader" />

      <!-- embed collection dialog box -->
      <CollectionEmbedCodeDialog />
    </template>

    <template v-else-if="isLibrary">
      <!-- edit btn -->
      <v-btn
        v-if="disableActions && !activeHeader.follow && !readMode"
        elevation="0"
        outlined
        @click="$emit('rename')">
        Edit
      </v-btn>

      <!-- share btn -->
      <CollectionShareDialog
        :members="activeHeader.members"
        :item="activeHeader"
        :invite-to-text-type="LIBRARY"
        @manageSharedUser="manageShare" />

      <!-- delete btn -->
      <LibraryDelete
        :icon="false"
        :item="activeHeader"
        :replace-router="{name:'libraries'}"
        wrapper-class="ma-0">
        <v-btn
          class="white--text"
          color="red"
          elevation="0">
          Delete
        </v-btn>
      </LibraryDelete>
    </template>

    <FollowLinkBtn
      v-else-if="isSharedCollection && useAddCollectionFollowButtonsToQuickLinks"
      class="mt-5"
      :link="followLink" />
  </div>
</template>
<script>
import {
  mapActions, mapState, mapGetters,
} from 'vuex';

import { ROUTE_COMMUNITY_COLLECTION } from '@/constants';
import { LIBRARY } from '@/constants/cores';

import { addSearchParams } from '@/utils';

import CollectionActionDetail from '@/components/CollectionDetails/CollectionActionDetail';
import CollectionEmbedCodeDialog from '@/components/CollectionDetails/CollectionEmbedCode/CollectionEmbedCodeDialog';
import CollectionFollow from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionFollow';
import CollectionPublishV2 from '@/components/Collections/CollectionsActions/CollectionPublishV2';
import CollectionShareDialog from '@/components/CollectionsLibrarysRelocate/ShareDialog';
import FollowLinkBtn from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionFollow/FollowLinkBtn';
import LibraryDelete from '@/components/Libraries/LibrariesActions/LibraryActionsComponent/LibraryDelete';
import SuggestCollectionDialog from '@/components/App/AppSuggestDialog/SuggestCollectionDialog';

import manageCollectionsActions from '@/mixins/ManageCollectionsActions';

export default {
  name: 'CollectionHeaderActions',
  components: {
    CollectionActionDetail,
    CollectionEmbedCodeDialog,
    CollectionFollow,
    CollectionPublishV2,
    CollectionShareDialog,
    FollowLinkBtn,
    LibraryDelete,
    SuggestCollectionDialog,
  },
  mixins: [manageCollectionsActions],
  props: {
    activeHeader: {
      type: Object,
      default: () => ({
      }),
      required: true,
    },
    canWorkWithModifyProduct: {
      type: Object,
      default: null,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: [Object, Boolean],
      default: false,
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
    isSharedCollection: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    readMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LIBRARY,
    };
  },
  computed: {
    ...mapState('Collections', ['currentCollectionSharingOption']),
    ...mapGetters('FeatureFlags', [
      'useAddCollectionFollowButtonsToQuickLinks',
    ]),
    ...mapGetters('UserRoles', ['canPublishInCurrentWorkspace']),
    ...mapGetters('Collections', ['isGroupShared', 'isActiveSuggestionCollection', 'checkIfMyCollection']),
    followLink() {
      const { url = '' } = this.currentCollectionSharingOption ?? {
      };
      return addSearchParams(url, {
        action: 'followV2',
      })?.href ?? '';
    },
    showCollectionPublish() {
      return this.disableActions && this.canPublishInCurrentWorkspace
        && (this.checkIfMyCollection || this.isGroupShared);
    },
    showHeaderActions() {
      return (
        ['collection-library', ROUTE_COMMUNITY_COLLECTION, 'collection-product-link'].includes(
          this.$route.name
        ) &&
        !this.isEditing &&
        !this.readMode
      );
    },
    disableActions() {
      return ![ROUTE_COMMUNITY_COLLECTION, 'community-collections'].includes(
        this.$route.name
      );
    },
  },
  methods: {
    ...mapActions({
      manageSharedUser: 'Libraries/manageSharedUser',
    }),
    manageShare(data) {
      this.manageSharedUser({
        variables: {
          ...data.variables,
          libraryId: this.item.id,
        },
        criteria: data.criteria,
      });
    },
    async ignoreSuggestionHandle() {
      await this.manageActions({
        action: 'ignoreSuggestion', item: this.activeHeader,
      });
      this.$router.push({
        name: 'community-collections',
        params: {
          id: 'community',
        },
      });
    },
  },
};
</script>
<style scoped>
</style>
