<template>
  <v-card-title class="header-items d-flex justify-space-between align-center">
    <span>
      {{ chatName }}
    </span>
    <span
      class="sourcery__icon-wrapper black"
      @click="$emit('close-dialog')">
      <v-icon
        color="white"
        size="15">
        mdi-close
      </v-icon>
    </span>
  </v-card-title>
</template>
<script>
export default {
  name: 'ModalHeader',
  props: {
    chatName: {
      type: String,
      default: 'Collection Chat',
    },
  },
};
</script>
