<template>
  <div v-if="canWorkWithProjectDocuments.allowed">
    <app-dialog
      v-model="isModalOpen"
      :value.sync="isModalOpen"
      width="514"
      content-class="v-dialog__form attachments-documents-wrapper">
      <content-download-document
        :images="images"
        :use-new-pdf-library="useNewPdfLibrary"
        :can-work-with-project-documents="canWorkWithProjectDocuments"
        v-on="$listeners"
        @closeDialog="closeDialog" />
    </app-dialog>

    <v-btn
      v-if="!images.length"
      class="sourcery__has-icon add-photos-btn"
      outlined
      @click="isModalOpen = true">
      <v-icon
        color="black"
        size="25">
        mdi-plus-circle
      </v-icon>
      Add Photos
    </v-btn>
    <v-icon
      v-else
      class="add-photos-icon"
      color="black"
      size="25"
      @click="isModalOpen = true">
      mdi-plus-circle
    </v-icon>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
import ContentDownloadDocument from '@/components/contentDownloadDocument';
export default {
  name: 'CollectionAddPhotos',
  components: {
    ContentDownloadDocument,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState({
      activeHeader: 'activeHeader',
    }),
    ...mapGetters('UserRoles', ['canModifyCarouselPhotos', 'findUserRoleInLibraryForActiveHeader', 'usersRolesListInCollection']),
    ...mapGetters('FeatureFlags', ['useNewPdfLibrary']),
    canWorkWithProjectDocuments() {
      const { members } = this.activeHeader || {
      };
      return this.canModifyCarouselPhotos(members
        ? this.usersRolesListInCollection
        : this.findUserRoleInLibraryForActiveHeader
      );
    },
  },
  watch: {
    isModalOpen(val) {
      if (val) {
        this.$emit('getUrlForValues', this.$route.params.id);
      } else {
        this.$emit('clearAllItems');
      }
    },
  },
  methods: {
    closeDialog() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .collections-photos-icon {
  display: flex;
  cursor: pointer;
  align-items: center;
  p {
    margin-bottom: 0px !important;
  }
}
.v-card__text {
  max-height: 300px;
  overflow: auto;
}
.add-photos-btn,
.add-photos-icon {
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 3;
}
.add-photos-icon {
  background: white;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
</style>
