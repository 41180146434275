<template>
  <div class="d-flex justify-center flex-column align-start app-listing-item-skeleton">
    <v-skeleton-loader
      class="app-listing-item-skeleton__image"
      type="image" />
    <v-skeleton-loader
      type="list-item-two-line" />
  </div>
</template>
<script>
export default {
  name: 'AppListingItemSkeleton',
};
</script>

<style scoped lang="scss">
   ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 100%;
    }
    .v-skeleton-loader__list-item-two-line {
      width: 218px;
      height: 100%;
    }
    .v-skeleton-loader__chip {
      width: 110px;
      height: 24x;
      border-radius: 8px;
    }
  }
  .app-listing-item-skeleton {
    margin: 15px 7px;
    gap: 30px;
  }
  .app-listing-item-skeleton__image {
    width: 218px;
    height: 144px;
    border-radius: 20px;
  }
</style>