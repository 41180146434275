<template>
  <SuggestDialog
    :is-icon="isIcon"
    :show-in-kebab-action="showInKebabAction"
    :disabled="disabled"
    :suggest-handler="suggestHandler" />
</template>
<script>
import {
  mapGetters, mapActions,
} from 'vuex';
import SuggestDialog from '@/components/App/AppSuggestDialog';
export default {
  name: 'SuggestCollectionDialog',
  components: {
    SuggestDialog,
  },
  props: {
    isIcon: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      type: String,
      default: null,
    },
    showInKebabAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('Collections', ['showSuggestButton']),
    disabled() {
      return this.showSuggestButton;
    },
  },
  methods: {
    ...mapActions('Collections', ['suggestCollection']),
    async suggestHandler(args) {
      const { selectedUsers, emailMessage } = args;
      this.suggestCollection({
        selectedUsers,
        emailMessage,
        collectionId: this.collectionId,
      });
    },
  },
};
</script>