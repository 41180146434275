<template>
  <thead class="v-data-table-header">
    <tr>
      <collection-table-header-item
        v-for="(head, index) in headers"
        :key="`${head.value}__custom-header`"
        :ref="`${head.value}__ref`"
        :index="index"
        :is-allowed-to-resize="head.isAllowedToResize"
        :table-height="tableHeight"
        :read-mode="readMode"
        :is-custom-sortable="head.isCustomSortable"
        :header-name="head.value"
        :column-id="head.column.id"
        :th-class="head.thClass"
        :header-size="head.columnSize ? head.columnSize.size : 0"
        :header-min-size="head.columnSize ? head.columnSize.minSize : 0"
        :selected-all-control-ids="selectedAllControlIds" />
    </tr>
  </thead>
</template>
<script>
import CollectionTableHeaderItem
  from '@/components/CollectionDetails/CollectionDetailsInfo/CollectionDetailsInfoHeader/CollectionTableHeaderItem/index.vue';
export default {
  name: 'CollectionDetailsInfoHeader',
  components: {
    CollectionTableHeaderItem,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    tableHeight: {
      type: [Number],
      default: 0,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    selectedAllControlIds: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/resizableElement.scss";
</style>
