<template>
  <v-btn
    :disabled="isDisabled"
    outlined
    @click="changeStatus">
    Share
  </v-btn>
</template>
<script>
import { mapMutations } from 'vuex';
import subscribeSharingOption from '@/mixins/subscribeSharingOption';
import { accessHelper } from '@/utils';
export default {
  name: 'CollectionPublishV2',
  mixins: [subscribeSharingOption],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isDisabled() {
      return this.canWorkWithPublish?.allowed == undefined ? false : !this.canWorkWithPublish?.allowed;
    },
    canWorkWithPublish() {
      return accessHelper(true);
    },
  },
  mounted() {
    this.subscribeSharing();
  },
  beforeDestroy() {
    this.subscribe?.unsubscribe();
  },
  methods: {
    ...mapMutations({
      openSharingDialog: 'Collections/setSharingDialog',
    }),
    async changeStatus() {
      this.openSharingDialog(true);
      return false;
    },
  },
};
</script>
