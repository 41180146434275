<!-- Avoid animations/v-skeleton for table cell for performance! -->
<template>
  <div class="app-table-cell-skeleton">
    <div class="app-table-cell-skeleton__text" />
    <div class="app-table-cell-skeleton__text" />
  </div>
</template>

<script>
export default {
  name: 'AppTableCellSkeleton',
};
</script>

<style scoped lang="scss">
  .app-table-cell-skeleton {
    padding: 30px 12px;
  }
  .app-table-cell-skeleton__text {
    width: 40%;
    height: 12px;
    background-color: var(--v-lightGrey-lighten1);
    border-radius: 4px;
    &:first-of-type {
      width: 100%;
    }
    margin-bottom: 4px;
  }
</style>