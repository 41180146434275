<template>
  <AppDialogLayoutToFollowTheEssence
    :can-use="canUseFollowCollection"
    :filtered-workspaces-list="filteredWorkspacesListForFollowing"
    :follow-menu="followMenu"
    :followed-ids="followedIds"
    :is-show-title-icon="isShowTitleIcon"
    :selected-workspaces="selectedWorkspaces"
    :title="followTitle"
    data-test="follow_collection_button"
    text-header-follow="Follow or Un-Follow Collections"
    text-to-disabled="You can\'t follow your own collection in its original workspace"
    text-top-input="Select or un-select workspaces to follow or un-follow collections"
    @on-done="followItemInWorkspaces"
    @update-dialog="followMenu = $event"
    @update-selected-workspaces="selectedWorkspaces = $event" />
</template>
<script>
import { accessHelper } from '@/utils';
import AppDialogLayoutToFollowTheEssence from '@/components/App/AppDialogLayoutToFollowTheEssence';
const FOLLOW = 'follow';
const FOLLOW_V2 = 'followV2';
const UNFOLLOW = 'unfollow';
import {
  NOT_PERMITTED_ACCESS, TYPE_FOLLOWER,
} from '@/constants/userPermissions';
import CollectionsApi from '@/services/graphql/collections';
import workspaceApi from '@/services/graphql/workspace';
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
export default {
  name: 'CollectionFollow',
  components: {
    AppDialogLayoutToFollowTheEssence,
  },
  props: {
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    group: {
      type: String,
      default: '',
    },
    isShowTitleIcon: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    FOLLOW,
    UNFOLLOW,
    NOT_PERMITTED_ACCESS,
    followMenu: false,
    selectedWorkspaces: [],
    disabledIds: [],
    nextToken: null,
    followedIds: [],
  }),
  computed: {
    ...mapState(['activeHeader']),
    ...mapState('Workspace', ['activeWorkspaceId', 'workspacesListForFollowing']),
    ...mapGetters('UserRoles', ['canFollowCollection']),
    ...mapGetters('FeatureFlags', ['useAddCollectionFollowButtonsToQuickLinks']),
    isActionFollow() {
      return this.$route.query?.action == FOLLOW;
    },
    isActionFollowNewFlow() {
      return this.$route.query?.action == FOLLOW_V2;
    },
    isInAutoFollowMode() {
      if (this.item.follow) {
        return false;
      }
      return this.isActionFollow;
    },
    isInAutoFollowModeNewFlow() {
      if (this.item.follow || !this.useAddCollectionFollowButtonsToQuickLinks) {
        return false;
      }
      return this.isActionFollowNewFlow;
    },
    followTitle() {
      const { item, group } = this;
      const { follow } = item || {
      };
      return !follow && !group !== 'followed' ? 'Follow' : 'Followed';
    },
    canUseFollowCollection() {
      if (this.isCommunityCollections) {
        return accessHelper(true);
      }
      return this.canFollowCollection;
    },
    filteredWorkspacesListForFollowing() {
      const { disabledIds } = this;
      return this.workspacesListForFollowing.map(e => ({
        ...e,
        disabled: disabledIds.includes(e?.id),
      }));
    },
    /**
     * Check if the active workspace ID exists in the filtered workspaces list.
     *
     * @returns {boolean} True if the active workspace exists in the list, otherwise false.
     */
    isActiveWorkspaceExistInWsList() {
      const { filteredWorkspacesListForFollowing: arr, activeWorkspaceId } = this;
      const isExist = arr.some(({ id }) => id === activeWorkspaceId);
      return isExist;
    },
  },
  watch: {
    followMenu: {
      /**
       * Watcher for the 'followMenu' property. Executes when the value changes.
       *
       * @param {boolean} val - The new value of 'followMenu'.
       */
      async handler(val) {
        if (val) {
          const { spinner } = this;
          spinner(true);
          await this.getWorkspacesListForFollowing();
          await this.getAvailableWs();
          spinner(false);
          const { activeWorkspaceId, disabledIds, followedIds } = this;
          /**
           * Check if the active workspace should be added to the selected workspaces based on specific conditions.
           * The active workspace is added if it is not disabled, not already followed, and exists in the workspace list.
           */
          if (
            !disabledIds.includes(activeWorkspaceId)
            && !followedIds.includes(activeWorkspaceId)
            && this.isActiveWorkspaceExistInWsList)
            this.selectedWorkspaces.push(activeWorkspaceId);
        } else {
          if (this.isInAutoFollowModeNewFlow) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                action: null,
              },
            });
          }
          this.followedIds = [];
          this.disabledIds = [];
          this.selectedWorkspaces = [];
        }
      },
    },
  },
  async created() {
    if (this.isInAutoFollowMode) {
      const { spinner } = this;
      spinner(true);
      await this.follow(this.activeWorkspaceId, FOLLOW);
      spinner(false);
    }
    if (this.isInAutoFollowModeNewFlow) {
      this.followMenu = true;
    }
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      updateActiveListingElement: 'updateActiveListingElement',
      updateCollectionName: 'Collections/updateCollectionName',
      unfollowCollection: 'Collections/unfollowCollection',
      getWorkspacesListForFollowing: 'Workspace/getWorkspacesListForFollowing',
      removeSuggestionFromList: 'Collections/removeSuggestionFromList',
    }),
    ...mapMutations(['spinner']),
    async getAvailableWs() {
      try {
        const { id: collectionId } = this.item;
        const { nextToken } = this;
        const response = await workspaceApi.listWorkspacesWithCollection({
          collectionId,
          limit: 200,
          nextToken,
        });
        const { data: wsIds, nextToken: repeatRequest } = response.data.response;
        this.nextToken = repeatRequest;
        const followedIds = [];
        const disabledIds = [];
        if (wsIds.length) {
          for (const ws of wsIds) {
            const { workspaceId, type } = ws;
            if (type === TYPE_FOLLOWER) followedIds.push(workspaceId);
            else disabledIds.push(workspaceId);
          }
        }
        this.selectedWorkspaces = [...this.selectedWorkspaces, ...followedIds];
        this.followedIds = [...this.followedIds, ...followedIds];
        this.disabledIds = [...this.disabledIds, ...disabledIds];
        if (this.nextToken) await this.getAvailableWs();
      } catch (err) {
        this.handleError(err);
      }
    },
    async followItemInWorkspaces({ saveFollowedIds, saveUnfollowedIds } = {
    }) {
      try {
        if (!this.canUseFollowCollection.allowed) {
          return;
        }
        this.spinner(true);
        saveFollowedIds.length && await Promise.all(saveFollowedIds.concat(saveFollowedIds).map(async w => await this.follow(w, FOLLOW)));
        saveUnfollowedIds.length && await Promise.all(saveUnfollowedIds.map(async w => await this.follow(w, UNFOLLOW)));
        const { item } = this;
        this.removeSuggestionFromList({
          id: item.id,
        });
        this.followMenu = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async follow(workspaceId, type = null) {
      const { item, activeWorkspaceId } = this;
      if (!this.canUseFollowCollection.allowed && !this.isInAutoFollowMode) {
        return;
      }
      try {
        const { id: collectionId, suggestionResource = null, suggested = false } = item;
        const followRequest = 'followCollectionV2';
        const unfollowRequest = 'unfollowCollectionV2';

        type === FOLLOW ? await CollectionsApi[followRequest]({
          collectionId,
          workspaceId,
          suggested: suggested || !!suggestionResource,
        }) : type === UNFOLLOW ?
          await CollectionsApi[unfollowRequest]({
            collectionId,
            workspaceId,
          }) : false;

        const follow = type === FOLLOW;
        this.updateCollectionName({
          ...item,
          follow,
        });
        if (this.activeHeader?.libraryId) {
          this.updateActiveListingElement({
            ...this.item,
            ...(workspaceId === activeWorkspaceId && {
              follow,
            }),
          });
        } else {
          if (workspaceId !== activeWorkspaceId) return false;
          this.unfollowCollection({
            collection: {
              ...item, follow,
            },
            list: this.$route.meta.type === 'community' ? 'community-collections' : this.$route.name,
          });
        }
      } catch (err) {
        this.handleError(err);
      }
    },
  },
};
</script>