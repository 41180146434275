import {
  COMMENT_RESOURCE_TYPE, COMMENTS_RESOURCES,
} from '@/constants/comments';
import {
  mapGetters, mapState,
} from 'vuex';
export default {
  props: {
    resourceType: {
      type: String,
      default: COMMENT_RESOURCE_TYPE.COLLECTION,
    },
    resourceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('Comments', ['hideResolvedCollectionCommentsV2', 'hideResolvedProjectCommentsV2']),
    hideResolvedToggle() {
      return this[COMMENTS_RESOURCES[this.resourceType].hideResolvedToggle];
    },
    isProjectType() {
      return this.resourceType == COMMENT_RESOURCE_TYPE.PROJECT;
    },
    isCollectionType() {
      return this.resourceType == COMMENT_RESOURCE_TYPE.COLLECTION;
    },
    ...mapGetters('Comments', ['totalCollectionsUnreadCommentsLength', 'totalProjectsUnreadCommentsLength']),
    currentResource() {
      return COMMENTS_RESOURCES[this.resourceType];
    },
    totalUnreadComments() {
      const { currentResource } = this;
      return this[currentResource.totalUnreadCommentsLength];
    },
  },
};
