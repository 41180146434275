<template>
  <v-card
    v-if="generalCommentsModal"
    v-model="commentsPanel"
    class="main-panel"
    accordion
    multiple>
    <ModalHeader
      :resource-type="resourceType"
      :resource-id="resourceId"
      @close-dialog="$emit('close-dialog')" />
    <ModalBody
      :resource-type="resourceType"
      :resource-id="resourceId"
      :general-comments-modal="generalCommentsModal" />
  </v-card>
</template>
<script>
import ModalHeader from '@/components/App/AppComments/AppSummaryModalContent/ModalHeader';
import ModalBody from '@/components/App/AppComments/AppSummaryModalContent/ModalBody';
import CommentsResources from '@/mixins/CommentsResources';
export default {
  name: 'AppSummaryModalContent',
  components: {
    ModalHeader,
    ModalBody,
  },
  mixins: [CommentsResources],
  inheritAttrs: false,
  props: {
    generalCommentsModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commentsPanel: [],
      comments: [],
    };
  },
  computed: {
    commentsLength() {
      return this.comments.length;
    },
  },
};
</script>
<style scoped lang="scss">
.main-panel {
  @media (max-width: 960px) {
    ::v-deep .all-comments {
      // grid-template-columns: 1fr !important;
      .cards, .default-comments {
        height: fit-content !important;
      }
    }
  }
  ::v-deep .all-comments {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 20px;
    .cards {
      height: 540px !important;
    }
    .default-comments {
      height: 546px;
      overflow: auto;
    }
    .v-card {
      box-shadow: none;
    }
  }
}
</style>
