<template>
  <div>
    <div
      ref="collectionWrapper"
      @scroll="!isListingView ? scrollHandle($event) : null">
      <SearchFilter
        v-if="!readMode"
        :disabled-input="isDisabledSearching"
        :filters-to-use="PRODUCT_FILTERS"
        placeholder="Search for Products"
        is-use-wrapper-margin
        @search="handleSearchProducts" />

      <!-- image carousel -->
      <CollectionsPhotos
        v-if="showCollectionPhotos"
        :images="images"
        :show-add-btn="activeHeader && !readMode && !isUnsuccessfulPayment || !isSharedCollection && !isEmbededCollection"
        @deleteFiles="deleteFiles"
        @error="onError"
        @getUrlForValues="getUrlForValues"
        @saveProjectDocuments="saveProjectDocuments" />

      <div class="sourcery__container d-flex flex-column gap-6">
        <!-- header -->
        <div class="d-flex justify-center">
          <CollectionHeaderSkeleton v-if="useLazyLoading && (isLoadingHeader || !activeHeader)" />
          <Header
            v-if="showCollectionName"
            :active-header="activeHeader"
            :is-community-collections="isCommunityCollections"
            :is-shared-collection="isSharedCollection"
            :read-mode="readMode"
            :view-as-member="renderViewAsMemberLink">
            <template #btns-start>
              <AppSummaryComments
                v-if="showComments"
                :general-comments-modal.sync="generalCommentsModal"
                :resource-id="collectionId"
                :resource-type="COMMENT_RESOURCE_TYPE.COLLECTION" />
            </template>
          </Header>
        </div>

        <!-- share link + app switcher -->
        <div class="d-flex justify-space-between align-center sticky-header">
          <ShareLink v-if="!readMode" />
          <AppSwitcherToListing v-if="!hideFooter" />
        </div>

        <!-- products -->
        <div :class="[{'schedule-container': !isListingView}, 'collections-container__internal-wrapper']">
          <div class="select-view-container">
            <template v-if="useSkeleton && isLoadingRowData">
              <ListingSkeleton v-if="isListingView" />
              <ScheduleSkeleton v-else />
            </template>

            <template v-else-if="showCollectionProducts">
              <!-- product blocks -->
              <AppListingBlockElement
                v-show="isListingView"
                :is-shown="isListingView"
                :data="sortedCurrentCollectionInfo"
                :data-listing-to-show="dataListingToShow"
                :show-loading-elements="isLoadingProducts || !hasFetchedProducts || isAddingNewProduct"
                :loading-elements-count="isAddingNewProduct ? 1 : 3"
                @openUpdateWindow="onShowExpandedDialog($event)" />

              <!-- table -->
              <CollectionDetailsInfo
                v-if="!isLoadingHeader && selectedCollectionView"
                v-show="!isListingView"
                ref="collectionTable"
                :is-shown="!isListingView"
                :is-community-collections="isCommunityCollections"
                :is-shared-collection="isSharedCollection || isEmbededCollection"
                :is-store-product-preload="isStoreProductPreload"
                :headers="sor.wrap(collectionTableHeaders)"
                :is-listing-view="isListingView"
                :data="sortedCurrentCollectionInfo"
                @added-product="onAddProduct" />
              <AppTableSkeleton v-else-if="!isListingView" />
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- add product button -->
    <template v-if="!hideFooter && (showCollectionCreate || readMode)">
      <AppAddButtonNoMenu
        v-if="$route.name === 'collection-library'"
        data-test="collections_plus_add_icon_button"
        :disabled="!canWorkWithAddProduct.allowed"
        :access="canWorkWithAddProduct"
        :click-callback="openCreateProductModal" />
    </template>

    <!-- create product dialog -->
    <CollectionsCreateProductDialog
      :collection-id-for-get-schema="collectionId"
      :prop-library-id="libraryId"
      :is-store-product-preload="isStoreProductPreload"
      :shared-link-collection-id="getSharedCollectionId"
      :editable="editableCollection"
      @added-product="onAddProduct" />
    <router-view
      :collection-id-for-get-schema="collectionId"
      :prop-library-id="libraryId"
      :is-store-product-preload="isStoreProductPreload"
      :shared-link-collection-id="getSharedCollectionId"
      :editable="editableCollection"
      :product="renderProduct"
      @added-product="onAddProduct" />

    <!-- collection info dialog -->
    <CollectionDetailsDialog
      :show-add-button-actions="false"
      @updateCollectionOrLibrary="updateCollection" />
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import {
  debounce, omit,
} from 'lodash';
import { SelectiveObjectReuse } from 'selective-object-reuse';
import { Storage } from 'aws-amplify';

import API from '@/services/graphql';
import CollectionsAPI from '@/services/graphql/collections';
import LibrariesApi from '@/services/graphql/libraries';
import ProductsAPI from '@/services/graphql/products';

import {
  TYPE_FOLLOW,
  DEBOUNCE_TIME_FOR_SEARCHING,
  ROUTE_COMMUNITY_COLLECTION,
} from '@/constants';
import {
  cacheControl, COLLECTION,
} from '@/constants/cores';
import { COMMENT_RESOURCE_TYPE } from '@/constants/comments';
import { PRODUCT_FILTERS } from '@/constants/searching/searchingFilters';
import {
  COLLECTION_ACCESS,
  COLLECTION_PERMISSION,
  ON_PRODUCT_UPDATE,
} from '@/constants/userPermissions';

import AppAddButtonNoMenu from '@/components/App/AppAddButtonNoMenu';
import AppListingBlockElement from '@/components/App/AppListingElements/AppListingBlockElement';
import AppSummaryComments from '@/components/App/AppComments/AppSummaryComments';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import AppTableSkeleton from '@/components/App/AppSkeleton/AppTable/AppTableSkeleton';
import CollectionsPhotos from '@/components/Collections/CollectionsPhotos';
import CollectionDetailsInfo from '@/components/CollectionDetails/CollectionDetailsInfo';
import CollectionHeaderSkeleton from '@/components/App/AppSkeleton/Collections/CollectionHeaderSkeleton';
import CollectionsCreateProductDialog from '@/components/Collections/CollectionsCreateProductDialog';
import Header from '@/components/Listing/CollectionHeader';
import ListingSkeleton from '@/components/App/AppSkeleton/ListingSkeleton';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
import ShareLink from '@/components/CollectionDetails/CollectionShareLink';

import fileAttempts from '@/mixins/fileAttempts';
import LoadingSpinnerReset from '@/mixins/LoadingSpinnerReset';
import ProductQuickLink from '@/mixins/ProductQuickLink';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import VirtualNewApproachScrollInCollection from '@/mixins/virtualNewApproachScrollInCollection';

import {
  getFullLinkForWorkspaces, hasViewerAccess, isFollower, parseStorageKey,
} from '@/utils';
import {
  EventBus,
  FOLLOWED_PRODUCT_IN_CURR_COLL,
} from '@/utils/eventBus';

export default {
  name: 'CollectionDetails',
  components: {
    AppAddButtonNoMenu,
    AppListingBlockElement,
    AppSummaryComments,
    AppSwitcherToListing,
    AppTableSkeleton,
    CollectionDetailsInfo,
    CollectionsCreateProductDialog,
    CollectionsPhotos,
    CollectionHeaderSkeleton,
    Header,
    ListingSkeleton,
    ScheduleSkeleton,
    ShareLink,
    CollectionDetailsDialog: () => import('@/components/CollectionsLibrarysRelocate'),
    SearchFilter: () => import('@/components/SearchFilter'),
  },
  mixins: [
    fileAttempts,
    LoadingSpinnerReset,
    ProductQuickLink,
    VirtualNewApproachScrollInCollection,
    ToggleViewCondition,
  ],
  props: {
    isAuth: {
      type: Boolean,
      default: true,
    },
    hideBreadcrumbs: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    isSharedCollection: {
      type: Boolean,
      default: false,
    },
    isEmbededCollection: {
      type: Boolean,
      default: false,
    },
    showCollectionProducts: {
      type: Boolean,
      default: true,
    },
    showCollectionName: {
      type: Boolean,
      default: true,
    },
    showCollectionPhotos: {
      type: Boolean,
      default: true,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PRODUCT_FILTERS,
    sor: new SelectiveObjectReuse(),
    userRoleInOriginalWs: null,
    isMasonryPage: true,
    nextToken: null,
    dataListingToShow: ['Manufacturer', 'Model (Manufacturer Series Name)'],
    images: [],
    rightAlignment: [
      'Budget Price',
      'Total Cost',
      'Budget Price - Project Override',
      'Library Budget Price',
    ],
    libraryName: '',
    generalCommentsModal: false,
    COMMENT_RESOURCE_TYPE,
    hasFetchedProducts: false,
    isAddingNewProduct: false,
  }),
  computed: {
    ...mapState([
      'userInfo',
      'activeHeader',
      'isLoadingRowData',
      'accessToken',
      'roleInResource',
      'updateLibraryOrCollection',
      'roles',
    ]),
    ...mapState('Collections', [
      'collectionsSchema',
      'currentCollectionInfo',
      'parsedObject',
      'sharedLink',
      'isCollectionInCustomLibrary',
    ]),
    ...mapGetters('Collections', [
      'allowedFieldsToShow',
      'selectedCollectionView',
      'sortedCurrentCollectionInfo',
      'currentCollectionInfoLength',
      'collectionTableHeaders',
    ]),
    ...mapState('Libraries', ['librariesList']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Collections', ['currentCollectionSharingOption']),
    ...mapGetters('UserProfile', ['isUnsuccessfulPayment', 'identityId']),
    ...mapGetters('Workspace', ['isPersonalWorkspace', 'getWorkspaceById']),
    ...mapGetters(['userId']),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapGetters('FeatureFlags', [
      'useSkeleton',
      'useIncreaseCaching',
      'useAddCollectionFollowButtonsToQuickLinks',
      'mobileViewOptimizationsForTradeShow',
      'useLazyLoading',
    ]),
    ...mapGetters('UserRoles', ['canAddProductToCollection', 'findUserRoleInLibraryForActiveHeader', 'usersRolesListInCollection']),
    ...mapGetters('ManageFiles', ['getFileByKey']),
    showHeaderActions() {
      return (
        ['collection-library', ROUTE_COMMUNITY_COLLECTION].includes(
          this.$route.name
        ) &&
        !this.readMode
      );
    },
    isAllowedToCreateProduct() {
      const { mobileViewOptimizationsForTradeShow: flag } = this;
      if (!flag) return false;
      const { allowed = false } = this.canWorkWithAddProduct || {
      };
      return allowed;
    },
    isDisabledSearching() {
      const { currentCollectionInfoLength: leng, nextToken } = this;
      if (!leng) return true;
      return !!(leng && nextToken);
    },
    pageId() {
      return this.$route?.params?.pageId;
    },
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    isCollectionRoute() {
      // The problem we are trying to solve here is
      // that the requests go when the user has
      // already changed the router and the request
      // needed a parameter from there, so we get errors.
      // Therefore, if you want to use this component,
      // add the appropriate value to the meta.
      // The problem will be solved in the task
      // https://app.asana.com/0/1202944615605726/1203004221549310/f
      return this.$route.meta.allowGetRows;
    },
    collectionName() {
      const { name = '' } = this.activeHeader || {
      };
      return name;
    },
    libraryId() {
      const { libraryId = '' } = this.activeHeader || {
      };
      return this.$route.params?.libraryId || libraryId;
    },
    originalWs() {
      const { workspaceId: id = null } = this.activeHeader ?? {
      };
      return id;
    },
    renderViewAsMemberLink() {
      if (!this.showViewAsMemberLink || !this.isAuth) {
        return null;
      }
      return {
        text: `View as a Member in `,
        name: this.getWorkspaceById(this.originalWs)?.name,
      };
    },
    showViewAsMemberLink() {
      return hasViewerAccess(this.userRoleInOriginalWs);
    },
    renderProduct() {
      if (this.getRoutesForProductModal.includes(this.$route.name)) {
        const { productId } = this.$route.params;
        if (this.$route.params?.item) {
          return this.$route.params?.item;
        }
        return this.currentCollectionInfo.find(({ id: itemId }) => itemId == productId);
      }
      return null;
    },
    showComments() {
      if (this.activeWorkspaceId != this.activeHeader?.workspaceId) {
        return false;
      }
      if (!this.activeHeader?.members) {
        return false;
      }
      return this.activeHeader?.members.find(member => member?.id == this.userId);
    },
    productsCount() {
      return this.activeHeader?.productsCount || 0;
    },
    dataProductsLength() {
      return this.currentCollectionInfo.length;
    },
    loadedProductsTitle() {
      return `Getting products (${this.dataProductsLength}/${this.productsCount})`;
    },
    canWorkWithAddProduct() {
      return this.canAddProductToCollection(this.findUserRoleInLibraryForActiveHeader);
    },
    collectionId() {
      return this.$route.params.id;
    },
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
    readMode() {
      const { params } = this.$route;
      return !params.id || this.isSharedCollection || this.isEmbededCollection;
    },
    fieldsToShow() {
      return this.sharedLink?.fieldsToShow.map(fieldId =>
        this.collectionsSchema?.schema.find(item => item.id === fieldId)?.name);
    },
    showAddIcon() {
      return this.activeHeader?.type !== 'viewer' && !this.activeHeader?.follow && this.$route.path !== '/shared';
    },
    showCollectionCreate() {
      return ['collection-library', 'collections', 'community-collection'].includes(this.$route.name);
    },
    breadcrumbItemsToCustomLibrary() {
      const { activeHeader, getCollectionsRoutePath } = this;
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: this.libraryName,
          disabled: false,
          to: getCollectionsRoutePath,
        },
        {
          text: activeHeader?.name,
          disabled: true,
        },
      ];
    },
    breadcrumbItemsToCommunity() {
      const { activeHeader, getCollectionsRoutePath } = this;
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: 'Community',
          disabled: false,
          to: getCollectionsRoutePath,
        },
        {
          text: activeHeader?.name,
          disabled: true,
        },
      ];
    },
    breadcrumbItemsDefaultLibrary() {
      const { activeHeader } = this;
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: 'Collections',
          disabled: false,
          to: getFullLinkForWorkspaces('collections/'.concat(this.getDefaultLibraryId)),
        },
        {
          text: activeHeader?.name,
          disabled: true,
        },
      ];
    },
    getBreadcrumbsList() {
      if (this.isCollectionInCustomLibrary) return this.breadcrumbItemsToCustomLibrary;
      if (this.isCommunityCollections) return this.breadcrumbItemsToCommunity;
      return this.breadcrumbItemsDefaultLibrary;
    },
    isDefaultLibrary() {
      return this.libraryId == this.activeWorkspaceId;
    },
    getCollectionsRoutePath() {
      const lId = this.libraryId || this.getDefaultLibraryId;
      let path = `collections/${lId}`;
      if (this.$route.name == 'community-collection') {
        path = 'community-collections/community';
      }
      return getFullLinkForWorkspaces(path);
    },
    getSharedCollectionId() {
      return this.sharedLink?.collectionId;
    },
    editableCollection() {
      return this.activeHeader?.editable;
    },
    isLoadingHeader() {
      return this.$store.state.Collections.isFetching.header;
    },
    isLoadingProducts() {
      return this.$store.state.Collections.isFetching.products;
    },
  },
  watch: {
    '$route.query.generalComments': {
      async handler(val) {
        if (val) {
          this.generalCommentsModal = !!val;
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.useAddCollectionFollowButtonsToQuickLinks ? await this.parsedLinkV2() : await this.parseSharedLink();
    !this.isSharedCollection && !this.isEmbededCollection && this.getRolesInCurrentCollection({
      workspaceId: this.activeWorkspaceId,
      resourceId: this.collectionId,
    });
    if (!this.isCommunityCollections) {
      this.subscribeCollectionsAccess();
    }
    this.manageLibraryInfo();
  },
  mounted() {
    EventBus.$on(FOLLOWED_PRODUCT_IN_CURR_COLL, (product) => {
      this.onFollowProduct(product);
    });
  },
  destroyed() {
    EventBus.$off(FOLLOWED_PRODUCT_IN_CURR_COLL);

    this.clearSelectedControlProductsIds();
    this.closeProgressLinear();
    this.resetCommentsState();
  },
  beforeDestroy() {
    this.setArrRoles([]);
    this.updateActiveElement(null);
    this.setCurrentCollectionInfo([]);
    this.setKeysToSearchingProducts(null);
    this.changeUpdateProductMode(false);
    clearTimeout(this.syncTime);
    this.removeSubscriptions([
      COLLECTION_PERMISSION,
      `${COMMENT_RESOURCE_TYPE.COLLECTION}_${this.collectionId}_slant`,
      `${COMMENT_RESOURCE_TYPE.COLLECTION}_${this.collectionId}_slant-list`,
      ON_PRODUCT_UPDATE,
      COLLECTION_ACCESS,
    ]
    );
  },
  methods: {
    ...mapActions({
      manageRecentlyAccessedCollectionProducts: 'StoredProducts/manageRecentlyAccessedCollectionProducts',
      getCollectionsSchema: 'Collections/getCollectionsSchema',
      getCollectionsViews: 'Collections/getCollectionsViews',
      getMyRoleInResource: 'getMyRoleInResource',
      getInfo: 'getInfo',
      parseGetRows: 'Collections/parseGetRows',
      handleError: 'handleError',
      recalculateOrderingOfRows: 'Collections/recalculateOrderingOfRows',
      openCreateProductModal: 'Collections/openCreateProductModal',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
      subscribeCollectionPermissionChange: 'UserPermissions/subscribeCollectionPermissionChange',
      parseFile: 'ManageFiles/parseFile',
      removeFileFromCache: 'ManageFiles/removeFilesFromCache',
      getCollectionSharingOptions: 'Collections/getCollectionSharingOptions',
      executeGetSlantsV2: 'Comments/executeGetSlantsV2',
      getResourcePermission: 'UserPermissions/getResourcePermission',
      subscribeSlantDocument: 'Comments/subscribeSlantDocument',
      subscribeSlantDocumentsList: 'Comments/subscribeSlantDocumentsList',
      subscribeOnProductUpdate: 'Collections/subscribeOnProductUpdate',
      updateActiveListingElement: 'updateActiveListingElement',
      openUpdateProductModal: 'Collections/openUpdateProductModal',
      subscribeCollectionsAccess: 'Collections/subscribeCollectionsAccess',
      getRolesInCurrentCollection: 'Collections/getRolesInCurrentCollection',
      searchProducts: 'Collections/searchProducts',
      getSharedLink: 'Collections/getSharedLink',
      setReadCollectionView: 'Collections/setReadCollectionView',
    }),
    ...mapMutations({
      spinner: 'spinner',
      setArrRoles: 'setArrRoles',
      setCurrentCollectionInfo: 'Collections/setCurrentCollectionInfo',
      updateActiveElement: 'updateActiveElement',
      clearSelectedControlProductsIds: 'Collections/clearSelectedControlProductsIds',
      changeUpdateProductMode: 'Collections/changeUpdateProductMode',
      setIsLoadingRowData: 'setIsLoadingRowData',
      setActiveWorkspaceId: 'Workspace/setActiveWorkspaceId',
      setProgressLinear: 'setProgressLinear',
      closeProgressLinear: 'closeProgressLinear',
      setKeysToSearchingProducts: 'Collections/setKeysToSearchingProducts',
      resetCommentsState: 'Comments/resetState',
      setIsCollectionInCustomLibrary: 'Collections/setIsCollectionInCustomLibrary',
      setIsFetching: 'Collections/setIsFetching',
    }),
    async manageLibraryInfo() {
      const { isCommunityCollections, libraryName, $route: route } = this;
      const { libraryId } = route.params || {
      };
      if (isCommunityCollections || libraryName || !libraryId) return;
      try {
        const params = {
          libraryId,
          workspaceId: this.activeWorkspaceId,
        };
        const {
          data: { response } = {
          },
        } = await LibrariesApi.getLibraryInfo(params);
        const { name, kind } = response ?? {
        };
        const isCustom = kind === 'custom';
        this.setIsCollectionInCustomLibrary(isCustom);
        this.libraryName = isCustom ? name : '';
      } catch (e) {
        console.log('manageLibraryInfo err', e);
      }
    },
    handleSearchProducts(items) {
      this.spinner(true);
      this.debouncedSearchProducts(items);
    },
    debouncedSearchProducts: debounce(function (items) {
      this.searchProducts(items);
      this.spinner(false);
    }, DEBOUNCE_TIME_FOR_SEARCHING),
    renderAddress(info) {
      const { location, address } = info;
      const addressObject = {
        location,
        address,
      };
      const paramToOmit = address ? 'location' : 'address';
      return omit(addressObject, [paramToOmit]);
    },
    async updateCollection(items) {
      this.spinner(true);
      const { name, description, logo, author } = items;
      const { libraryId, id } = this.updateLibraryOrCollection;
      try {
        const { data } = await CollectionsAPI.update({
          libraryId,
          collectionId: id,
          name,
          description,
          logo,
          author,
          workspaceId: this.activeWorkspaceId,
          ...this.renderAddress(items),
        });
        this.updateActiveListingElement(data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async onError(file) {
      const { key } = parseStorageKey(file.key);
      this.addAttempts(key);
      if (!this.haveEnoughAttempts(key)) {
        return;
      }
      const keys = Object.values(file.thumbnails).reduce((acc = [], thumbnail) => {
        if (thumbnail?.key) {
          const { key } = parseStorageKey(thumbnail.key);
          return [...acc, key];
        }
      }, []);
      this.removeFileFromCache({
        keys: [key, ...keys],
      });
      this.images = await this.getImagesUrl(this.images, true);
    },
    async getCollectionRelatedInfo(collectionId) {
      await this.getMyRoleInResource({
        resourceId: this.collectionId,
        showSpinner: !this.useLazyLoading,
      });
      const criteria = this.isCommunityCollections ? 'getCommunityCollectionInfo' : 'getLibraryCollectionInfo';
      const useLazyLoad = this.useSkeleton || this.useLazyLoading;
      if (useLazyLoad) {
        this.setIsFetching({
          header: true,
        });
      } else {
        this.spinner(true);
      }
      const { accessToken } = this;
      await this.getInfo({
        collectionId,
        criteria,
        queryType: 'Collection',
        showInCollectionDetails: true,
        accessToken,
      });
      if (useLazyLoad) {
        this.setIsFetching({
          header: false,
        });
      }
      if (this.activeHeader) {
        const { libraryId } = this;
        await this.getUrlForValues(collectionId);
        await this.getCollectionsSchema({
          collectionId,
          libraryId,
        });
        await this.getCollectionsViews({
          collectionId,
          libraryId,
        });
        await this.getRowsToCollections(collectionId);
        this.getUserInfoInOriginalWs(collectionId);
      }
      this.spinner(false);
    },
    async getUserInfoInOriginalWs(resourceId) {
      const { roleInResource: role, originalWs: workspaceId } = this;
      if (!workspaceId || role && !isFollower(role)) return;
      try {
        const { data } = await API.getMyRoleInResource({
          workspaceId,
          resourceType: COLLECTION,
          resourceId,
        });
        this.userRoleInOriginalWs = data.response;
      } catch (err) {
        console.log('err getMyRoleInResource', err);
      }
    },
    async getRowsToCollections(collectionId) {
      if (!this.isCollectionRoute) {
        return;
      }
      const { accessToken, libraryId } = this;
      await this.getCollectionRows({
        collectionId,
        libraryId,
        limit: 15,
        nextToken: this.nextToken,
        ...(accessToken && {
          accessToken,
        }),
      });
    },
    async getSharedCollectionLink() {
      try {
        const accessToken = this.$route.query?.accessToken;
        await this.getSharedLink({
          collectionId: this.collectionId,
          accessToken,
        });
        const { collectionId } = this.sharedLink;
        if (!collectionId) {
          this.handleError('The current collection is not found or does not exist');
          return;
        }
        this.getCollectionRelatedInfo(collectionId);
      } catch (err) {
        console.log('err', err);
        this.handleError(err);
      }
    },
    async parseSharedLink() {
      if (this.isSharedCollection || this.isEmbededCollection) {
        this.getSharedCollectionLink();
        return;
      }
      const { collectionId: id } = this;
      await this.getCollectionSharingOptions({
        id,
        showSpinner: !this.useLazyLoading,
      });
      this.getCollectionRelatedInfo(id);
      this.subscribeCollectionPermissionChange();
      this.subscribeOnProductUpdate({
        collectionId: id,
      });
    },
    async parsedLinkV2() {
      const { collectionId: id } = this;
      const sharedCollectionData = {
        ...this.isSharedCollection && {
          useIam: true,
          accessToken: this.$route.query?.accessToken,
        },
      };
      await this.getCollectionSharingOptions({
        id,
        showSpinner: !this.useLazyLoading,
        ...sharedCollectionData,
      });
      if (this.isSharedCollection || this.isEmbededCollection) {
        this.getSharedCollectionLink();
        return;
      }
      this.getCollectionRelatedInfo(id);
      this.subscribeCollectionPermissionChange();
      this.subscribeOnProductUpdate({
        collectionId: id,
      });
    },
    setProgressLinearAction() {
      this.setProgressLinear({
        title: this.loadedProductsTitle,
      });
    },
    async getCollectionRows(variables) {
      if (!this.dataProductsLength && !this.useLazyLoading) {
        this.setProgressLinearAction();
      }
      if (this.useLazyLoading) {
        this.setIsFetching({
          products: true,
        });
      } else {
        this.spinner(true);
      }
      try {
        const { libraryId, collectionId, collectionName } = this;
        let response;
        response = await CollectionsAPI.getCollectionRows({
          ...variables,
          workspaceId: this.activeWorkspaceId,
          libraryId,
        });
        const { data, nextToken } = response.data.response;
        this.nextToken = nextToken;
        if (this.showComments) this.getComments(data);

        if (data.length) {
          let dataArray = [];
          data.forEach((row, index) => {
            this.parseGetRows({
              fields: row.fields,
              index,
            });
            dataArray = [...dataArray, this.parsedObject];
          });
          const products = dataArray;
          this.recalculateOrderingOfRows([...this.currentCollectionInfo, ...products]);
        }
        this.setProgressLinearAction();
        if (this.nextToken) {
          await this.getRowsToCollections(collectionId);
        }
        if (!this.nextToken) {
          this.isStoreProductPreload && this.manageRecentlyAccessedCollectionProducts({
            products: this.currentCollectionInfo,
            libraryId,
            collectionId,
            collectionName,
          });
          this.closeProgressLinear();
        }
      } catch (err) {
        console.log('data err', err);
        this.closeProgressLinear();
      } finally {
        if (this.useLazyLoading) {
          this.setIsFetching({
            products: false,
          });
          this.hasFetchedProducts = true;
        } else {
          this.spinner(false);
        }
      }
    },
    async getComments(data) {
      const { collectionId } = this;
      await this.executeGetSlantsV2({
        payload: data,
        resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
        resourceId: collectionId,
        viewInfoLength: this.currentCollectionInfo.length,
      });
      await this.getResourcePermission({
        resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
        resourceId: collectionId,
      });
      this.subscribeSlantDocument({
        resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
        resourceId: collectionId,
      });
      this.subscribeSlantDocumentsList({
        resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
        resourceId: collectionId,
      });
    },
    async getUrlForValues(collectionId) {
      if (!this.useLazyLoading) this.spinner(true);
      try {
        const { data } = await CollectionsAPI.listCollectionPhotos({
          collectionId,
        });
        this.images = await this.getImagesUrl(data.response.data);
      } catch (err) {
        console.log(err);
      } finally {
        this.spinner(false);
      }
    },
    async saveProjectDocuments(files) {
      const identityId = this.identityId;
      for (let i = 0; i < files.length; i++) {
        try {
          this.spinner(true);
          const { collectionId } = this;
          const { data } = await CollectionsAPI.declareCollectionPhoto({
            collectionId,
            workspaceId: this.activeWorkspaceId,
          });
          const metadata = {
            identity_id: identityId,
            photo_id: data.response.id,
            collection_id: collectionId,
            username: this.userInfo.sub,
            workspace_id: this.activeWorkspaceId,
          };
          await Storage.put(`${data.response.id}/${files[i].name}`, files[i].file, {
            level: 'protected',
            identityId,
            contentType: files[i].type,
            metadata,
            cacheControl,
          });
          await this.getPhotoSubscription({
            collectionId,
            photoId: data.response.id,
          });
        } catch (err) {
          this.handleError(err);
          this.spinner(false);
        }
      }
    },
    async getPhotoSubscription(variables) {
      try {
        const { collectionId, photoId } = variables;
        this.spinner(true);
        await CollectionsAPI.subscribeCollectionPhoto({
          collectionId,
          photoId,
        }).subscribe({
          next: async ({ value }) => {
            await this.setNewImages(value.data.response);
            this.spinner(false);
          },
        });
      } catch (err) {
        console.log('subscribeCollectionPhoto', err);
        this.spinner(false);
      }
    },
    async setNewImages(file) {
      const [fileWithUrl] = await this.getImagesUrl([file]);
      const imgs = this.images.map(e => {
        if (e.id === fileWithUrl.id) {
          return {
            ...e, thumbnails: fileWithUrl.thumbnails,
          };
        }
        return e;
      });
      if (!(imgs.some(e => e.id === fileWithUrl.id))) {
        imgs.push(fileWithUrl);
      }
      this.images = imgs;
    },
    async getImagesUrl(valueWithData) {
      const value = valueWithData;
      for (let i = 0; i < value.length; i++) {
        const itemImg = value[i];
        if (itemImg.key) {
          const { identityId, key } = parseStorageKey(itemImg.key);
          if (this.useIncreaseCaching) {
            await this.parseFile({
              key, config: {
                level: 'protected',
                identityId,
              },
            });
            itemImg.url = this.getFileByKey(key);
          } else {
            itemImg.url = await Storage.get(
              key, {
                level: 'protected',
                identityId,
              }
            );
          }
          itemImg.type = 'image/jpeg';
          itemImg.filename = key.split('/')[1];
        }
        if (itemImg.thumbnails) {
          const thumbnails = Object.keys(itemImg.thumbnails);
          for (let q = 0; q < thumbnails.length; q++) {
            const thumbnail = thumbnails[q];
            if (itemImg.thumbnails[thumbnail]) {
              const thumbItem = itemImg.thumbnails[thumbnail];
              const { identityId, key } = parseStorageKey(thumbItem.key);
              if (this.useIncreaseCaching) {
                await this.parseFile({
                  key, config: {
                    level: 'protected',
                    identityId,
                  },
                });
                thumbItem.url = await this.getFileByKey(key);
              } else {
                thumbItem.url = await Storage.get(
                  key, {
                    level: 'protected',
                    identityId,
                  }
                );
              }
            }
          }
        }
      }
      return value;
    },
    async deleteFiles(photoIds) {
      this.spinner(true);
      try {
        await CollectionsAPI.deleteCollectionPhotos({
          collectionId: this.collectionId,
          photoIds,
          workspaceId: this.activeWorkspaceId,
        });
        this.images = this.images.filter(el => !photoIds.includes(el.id));
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    onAddProduct(data) {
      this.parseGetRows({
        fields: data.fields,
        index: this.dataProductsLength,
      });
      const payload = [...this.currentCollectionInfo, this.parsedObject];
      this.setCurrentCollectionInfo(payload);
      if (this.showComments) {
        this.executeGetSlantsV2({
          payload,
          resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
          resourceId: this.$route.params.id,
          viewInfoLength: this.currentCollectionInfo.length,
        });
      }
    },
    async onFollowProduct({ productId, collectionId }) {
      if (!productId) return;
      if (collectionId != this.collectionId) return;

      try {
        this.isAddingNewProduct = true;

        const res = await ProductsAPI.getCollectionProduct({
          collectionId: this.collectionId,
          productId: productId,
          workspaceId: this.activeWorkspaceId,
        });

        const fields = res.data?.response?.fields;
        if (fields) {
          this.parseGetRows({
            fields: fields,
            index: this.dataProductsLength,
          });
          const payload = [...this.currentCollectionInfo, {
            ...this.parsedObject,
            'mode': TYPE_FOLLOW,
          }];
          this.setCurrentCollectionInfo(payload);
        }
      } finally {
        this.isAddingNewProduct = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.sticky-header {
  position: sticky;
  left: 0;
}
.v-breadcrumbs-sticky {
  position: sticky;
  left: 0;
}
.masonry-item {
  width: 300px;
  gap: 10px;
  overflow-wrap: anywhere;
  align-content: start;
  margin: 15px 7px;
  position: relative;
  cursor: pointer;
  display: grid;
  ::v-deep .wrapper__icon {
    margin: auto;
  }
}
.collection-main-header {
  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px;
    &.mobile-view-trade-show-container {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        flex-direction: column;
        padding: 0;
        &.collection-main-header-actions__more-tools {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
.action-footer {
  display: flex;
  gap: 20px;
  align-items: center;
}
.image-sticky {
  position: sticky;
  left: 0;
}
::v-deep .collections-container__internal-wrapper {
  th {
    &:nth-child(2) {
      left: unset !important;
      max-width: unset !important;
      min-width: unset !important;
      z-index: 2 !important;
    }
  }
  td {
    &:nth-child(2) {
      left: unset !important;
      max-width: unset !important;
      min-width: unset !important;
      position: static !important;
      z-index: 2 !important;
    }
  }
}
</style>
