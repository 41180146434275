export default {
  computed: {
    collectionTableRef() {
      return this.$refs.collectionTable;
    },
    collectionTableRefEl() {
      return this.collectionTableRef.$el;
    },
    collectionTableHeight() {
      return this.collectionTableRefEl.scrollHeight;
    },
    collectionWrapperHeight() {
      return this.$refs.collectionWrapper.scrollHeight;
    },
  },
  methods: {
    onScrollCollection(data) {
      this.collectionTableRef.onScroll(data);
    },
    scrollHandle(e) {
      if (!this.collectionTableRef) return;

      const { scrollLeft, scrollTop } = e.target;
      const { collectionWrapperHeight, collectionTableHeight } = this;
      const headerHeight = collectionWrapperHeight - collectionTableHeight;
      this.onScrollCollection({
        target: {
          scrollTop: scrollTop - headerHeight,
          scrollLeft,
        },
      });
    },
  },
};

