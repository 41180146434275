<template>
  <th
    :data-ref="`${headerName}__ref`"
    :data-index="index"
    role="columnheader"
    :class="[isActiveResizable && 'resizable-element__header', thClass]"
    :style="{ width: `${headerSize}px`}"
    scope="col"
    aria-label
    @click="isCustomSortable
      // eslint-disable-next-line max-len
      ? setCollectionsViewsSorting({sortingData: sortHelper(selectedCollectionView.id, selectedSortingData.sortBy !== headerName ? headerName : selectedCollectionView.sortingField || '')})
      : () => {}"
    @mouseleave="onMouseLeave(headerName)"
    @mouseover="onMouseOver(headerName)">
    <div
      :style="{'margin-left': '-16px'}"
      class="v-data-table-header__wrapper">
      <vue-draggable-resizable
        v-if="isAllowedToResize"
        class="d-flex align-center resizable-element"
        class-name-handle="resizable-handle"
        :w="headerSize"
        :h="48"
        :min-width="headerMinSize"
        :axis="'x'"
        :handles="['mr']"
        :on-resize="onResizeCallback"
        :resizable="canWorkWithResizeColumn && isResizable && !readMode"
        :active="isResizable"
        :draggable="false"
        @resizing="onResizeColumn({ name: headerName })"
        @resizestop="onResizeColumnStop({ width: arguments[2], colId: columnId, resourceType: 'collection', resourceId: $route.params.id,
                                          viewId: selectedCollectionView.id, isCustomSortable, })">
        <!-- handles prop name -->
        <template #mr>
          <div
            class="resizable-handle-element"
            @mouseover.stop="isCanceledResizable ? setActiveColumn(headerName): null"
            @mouseleave.stop="setHoveredColumn(isCanceledResizable ? { name: headerName } : { })">
            <div
              :class="{ 'resizable-handle-element__selected-border': isActiveResizable }" />
            <div
              v-show="isActiveResizable"
              :style="{height: `${tableHeight - 48}px`}"
              class="resizable-handle-element__selected-border__bottom" />
          </div>
        </template>
        <template v-if="headerName == 'order'">
          <ControlSelects
            v-if="!readMode"
            :key="`${headerName}___control-value`"
            :value="selectedAllControlIds"
            class="mr-3"
            @change="$emit('change-control-selects', $event)" />
        </template>
        <template v-else>
          <span>{{ headerName }}</span>
        </template>
        <app-sort-arrows
          v-if="isCustomSortable"
          :sort-by="headerName"
          :sorting-data="selectedSortingData"
          :sortable-options="SORTABLE_OPTIONS" />
      </vue-draggable-resizable>
      <template v-else-if="headerName!=='order'">
        <div class="d-flex align-center table-header__not-resizable">
          <span>{{ headerName }}</span>
          <app-sort-arrows
            v-if="isCustomSortable"
            :sort-by="headerName"
            :sorting-data="selectedSortingData"
            :sortable-options="SORTABLE_OPTIONS" />
        </div>
      </template>
      <table-header-actions
        v-if="isCustomSortable"
        :sort-by="headerName"
        :sorting-data="selectedSortingData"
        :default-sort-by="selectedCollectionView.sortingField"
        @sort="setCollectionsViewsSorting({sortingData: sortHelper(selectedCollectionView.id, $event.sortBy, $event.sortOrder)})" />
    </div>
  </th>
</template>
<script>
import AppSortArrows from '@/components/App/AppSortArrows';
import tableHeaderActions
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableHeaderActions';
import ResizableMixin from '@/mixins/ResizableMixin';
import tableSortingMixin from '@/mixins/tableSortingMixin';
import VueDraggableResizable from 'vue-draggable-resizable';
import ControlSelects from '@/components/Product/ProductCell/ProductCellControlSelects';
export default {
  name: 'CollectionTableHeaderItem',
  components: {
    VueDraggableResizable,
    AppSortArrows,
    tableHeaderActions,
    ControlSelects,
  },
  mixins: [ResizableMixin, tableSortingMixin],
  props: {
    readMode: {
      type: Boolean,
      default: false,
    },
    isAllowedToResize: {
      type: Boolean,
      default: false,
    },
    deprecated: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: [Number],
      default: 0,
    },
    isCustomSortable: {
      type: Boolean,
      default: false,
    },
    columnId: {
      type: String,
      default: '',
    },
    headerName: {
      type: String,
      default: '',
    },
    thClass: {
      type: String,
      default: '',
    },
    headerSize: {
      type: Number,
      default: 0,
    },
    selectedAllControlIds: {
      type: Boolean,
      default: false,
    },
    headerMinSize: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/resizableElement.scss";
$accent-color: rgba(#71d6e0, .2);
$accent-color-header: #DEEFF1;
$grey-color: rgba(#757575, .1);
::v-deep {
  // Changes background of header cell
  .table-container__header-cell {
    background: $grey-color;
    &--hover {
      background: $accent-color;
    }
    &--hover-active {
      background: $accent-color-header;
    }
  }
}
.table-header__not-resizable {
  padding-left: 16px;
  height: 100%;
}
</style>
