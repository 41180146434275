import {
  mapActions, mapGetters,
} from 'vuex';
export default {
  methods: {
    ...mapActions('Collections', ['openUpdateProductModal']),
    onShowExpandedDialog(item) {
      try {
        if (this.getRoutesForProductModal.includes(this.getProductNameRoute)) {
          const { name: routeName, params } = this.$route;
          const { libraryId = '' } = params || {
          };
          const name = this.getProductNameRoute;
          (routeName !== name) && this.$router.push({
            name,
            query: this.$route.query,
            params: {
              disableProxyDialog: true,
              productId: item.id,
              item,
              ignoreMiddleware: true,
              ...libraryId && {
                libraryId,
              },
            },
          }).catch(() => {
          });
          return;
        }
        this.openUpdateProductModal(item);
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters('Collections', ['getRoutesForProductModal']),
    getProductNameRoute() {
      if (this.$route.name == 'embed-collection') {
        return 'embed-collection-product-link';
      }
      if (this.isSharedCollection) {
        return 'shared-collection-product-link';
      }
      if (this.isCommunityCollections) {
        return 'community-collection-product-link';
      }
      return 'collection-product-link';
    },
  },
};
