<template>
  <app-icon-btn
    wrapper-class="d-flex"
    bottom
    :disabled="disabled"
    :show-tooltip="disabled"
    class="collection-action-button"
    @click="openSharingDialog">
    <template
      #img>
      <span
        class="small-p d-flex align-center"
        style="width:100%;">
        <img
          class="v-icon__dialog-icon"
          src="@/assets/icons/share-action-icon.svg"
          alt="share">
        Share
      </span>
    </template>
  </app-icon-btn>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  name: 'CollectionBtnSharingOption',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations('Collections', ['setSharingDialog']),
    openSharingDialog() {
      !this.disabled && this.setSharingDialog(true);
    },
  },
};
</script>
<style scoped>
</style>
