import {
  mapGetters, mapState,
} from 'vuex';
import productHeaders from '@/constants/productHeaders';
import { hasEditorAccess } from '@/utils';
export default {
  data() {
    return {
      productHeaders,
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', [
      'selectedCells',
    ]),
    ...mapGetters(['userId']),
    ...mapGetters({
      isSelectedCell: 'ProjectDetailsTableSchedule/isSelectedCell',
      isReadOnlyCell: 'ProjectDetailsTableSchedule/isReadOnlyCell',
      findUserRoleInLibraryForActiveHeader: 'UserRoles/findUserRoleInLibraryForActiveHeader',
    }),
    allowedForAppropriateRoles() {
      return hasEditorAccess(this.findUserRoleInLibraryForActiveHeader) && !!this.userId;
    },
  },
  methods: {
    borderedCell({ columnType, headerId, columnName, currentRowId }) {
      const {
        isSelectedCell,
        isHeaderTypeFormula,
        allowedForAppropriateRoles,
      } = this;
      return columnName !== 'order'
        && allowedForAppropriateRoles
        && !isHeaderTypeFormula(columnType, headerId)
        && isSelectedCell({
          columnName, currentRowId,
        });
    },
    isHeaderTypeFormula({ columnType, headerId = null }) {
      const { isReadOnlyCell } = this;
      return columnType?.includes('FORMULA') || isReadOnlyCell(headerId);
    },
  },
};
